export default function getFileType(fileName) {
  if (fileName == null || fileName === '') return 'Unknown'
  if (fileName.includes('/') || fileName.includes('\\') || fileName.match(/^https?:\/\//i)) return 'link'
  const parts = fileName.split('.')
  const extension = parts.length > 1 ? parts.pop().toLowerCase() : ''
  const fileTypes = {
    doc: 'document',
    docx: 'document',
    ppt: 'ppt',
    pptx: 'ppt',
    pdf: 'pdf',
    mp4: 'video',
    avi: 'video',
    mkv: 'video',
    jpg: 'photo',
    jpeg: 'photo',
    png: 'photo',
    gif: 'photo',
    xls: 'excel_sheet',
    xlsx: 'excel_sheet',
    link: 'link',
    com: 'link',
    txt: 'text',
    csv: 'csv',
    json: 'json',
    zip: 'archive',
    rar: 'archive',
    mp3: 'audio',
    wav: 'audio',
  }
  return fileTypes[extension] ?? 'Unknown'
}
